<template>
  <div class="container-wrapper-input" :class="[color]">
      <p class="title">{{title}}</p>
    <div
    class="input-control"
    :class="{completed: completed, [color]: true}">
      <p class="error">{{error}}</p>
      <slot>
        <input
        :class="[color]"
        :maxlength="maxlen"
          ref="input"
          v-bind="$attrs"
          :placeholder="placeholder"
          v-on="inputListeners">
      </slot>
      <p class="warn">{{alert}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'input-form',
  inheritAttrs: false,
  props: {
    maxlen: Number,
    alert: String,
    error: String,
    placeholder: String,
    title: String,
    text: String,
    color: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      isShow: false,
      completed: !!this.text
    }
  },
  beforeUpdate () {
    const { input } = this.$refs
    if (!input.value) {
      this.completed = false
    }
  },
  updated () {
    this.completed = !!this.$refs.input.value
  },
  mounted () {
    const { input } = this.$refs
    this.completed = !!input.value
  },
  computed: {
    inputListeners: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          input: function (event) {
            vm.$emit('input', event.target.value)
            if (!event.target.value) {
              vm.completed = false
            }
          },
          blur: function (event) {
            if (event.target.value) {
              vm.completed = true
            }
          },
          focus: function () {
            vm.completed = false
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.container-wrapper-input  {
  text-align: left;
  .title {
    margin-bottom: 0px;
    color: inherit;
    font-size: 18px;
    text-align: left;
    font-family: "Roboto", sans-serif !important;
    left: 0;
  }
  input {
    color: inherit;
    &::placeholder {
      color: rgba(121, 113, 113, 0.377);
    }
  }
  .error {
    position: absolute;
    left: 5px;
    top: 0;
    font-size: 12px;
    color: #f00;
  }
  .warn {
    position: absolute;
    left: 5px;
    bottom: 0;
    font-size: 12px;
    color: #ccc;
  }
}
.shadow {
  .input-control {
    border-style: none;
    padding: .5rem;
    box-shadow: 0 2px 12px -2px rgba(68, 68, 68, 0.226);
  }
}
.font-small {
  .input-control {
    height: 50px;
    padding: 0.5em;
  }
  .title {
    font-weight: none;
    font-size: 14px;
  }
  .input-control {
    input, label, input::placeholder {
      font-size: 14px;
    }
  }
}
 .primary {
    color: #6a4c7cc0 !important;
  }
.input-control {
  position: relative;
  background-color: #fff;
  border: 2px solid;
  width: 100%;
  height: 60px;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  position: relative;
  input {
    width: 100%;
    flex: 1;
    border-style: none;
    outline-style: none;
    font-size: 16px;
    background: transparent;
    text-align: left;
    /* color: #4B335A; */
    &::placeholder {
      font-size: 16px;
    }
  }
  &.completed {
    background-color: #50366054;
    input {
      color: #fff !important;
    }
  }
  input[disabled] {
    background-color: transparent;
  }
  input {
    font-weight: 100;
    color: rgb(124, 124, 124);
    font-family: 'NexaLight';
    &::placeholder {
      font-family: "NexaLight";
    }
    @media(max-width:560px){
      font-size: 16px;
    }
  }
}
.input {
  display: flex;
  align-items: center;
  input {
    display: none;
  }
}
input:checked + .btn-radio .item {
  display: block;
}
input {
  width: 100%;
  flex: 1;
  border-style: none;
  outline-style: none;
  font-size: 1.3rem;
  background: transparent;
  text-align: left;
  color: #fc0;
  &::placeholder {
    font-size: 1.3rem;
  }
}
input[disabled] {
  background-color: transparent;
}
input::placeholder, input, label {
  font-weight: 100;
  color: inherit;
  font-family: 'NexaLight';
  @media(max-width:560px){
    font-size: 16px!important;
  }
}
input {
  color: inherit;
}

</style>
