<template>
  <ul role="listitem" tabindex="0"  class="widget" :style="{backgroundColor: themeStyle.backgroundStyle.color, color: themeStyle.textStyle.color }">
    <template v-for="(item, index) of countries">
      <li role="option"
        v-if="item"
        :key="index"
        :class="{active: item.id === current}"
        @click.prevent="() => setData(item.id)">
        {{ fieldName ? item[fieldName] : item.nome}}
      </li>
    </template>
  </ul>
</template>
<script>
export default {
  name: 'widget-datalist',
  props: {
    value: {
      type: String,
      default: ''
    },
    fieldName: {
      type: String,
      default: undefined
    },
    themeStyle: {
      type: Object,
      default: () => ({
        backgroundStyle: { color: '#4B335A' },
        textStyle: { color: 'color: #FDB00B' }
      })
    },
    data: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      current: -1,
      countries: this.data.filter(item => item !== null)
    }
  },
  methods: {
    setData (index) {
      this.current = index
      this.$emit('setData', this.current !== -1 ? this.countries.filter(item => item.id === index)[0] : null)
    }
  },
  created () {
    this.current = this.countries.indexOf(this.value)
  }
}
</script>
<style lang="scss" scoped>
  .widget {
    position: absolute;
    list-style: none;
    left: 0;
    max-height: 300px;
    padding-bottom: 30px;
    width: 88%;
    max-width: 300px;
    z-index: 999;
    text-align: left;
    color: #fff;
    background-color: #4B335A;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 18px;
    padding-left: 0;
    border-radius: 6px;
    &.cover {
      max-width: 100%;
    }
    &.align-start {
      li {
        padding-left: 0;
        margin: 0;
      }
    }
    &.full-width {
      width: 100%;
      li {
        margin-left: 0;
        padding-right: 0;
      }
    }
    &.separator {
      border-radius: 0;
      li {
        border-bottom: 1px solid rgba(255, 255, 255, 0.486);
      }
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #4b335ad8;
      background-color: #fff
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px #4b335a;
      background-color: rgb(192, 183, 183);
    }
    &::-webkit-scrollbar-button:vertical:increment {
      background-position: -64px -16px;
    }
    &::-webkit-scrollbar-button:vertical:decrement {
      background-position: 0 -16px;
    }
    &::-webkit-scrollbar-button:horizontal:increment {
      background-position: -32px -16px;
    }
    &::-webkit-scrollbar-button:horizontal:decrement {
      background-position: -96px -16px;
    }
    &::-webkit-scrollbar-corner {
      background-color: #57346d;
    }
    @media(max-width:560px){
        width: 100%!important;
    }
    li {
      cursor: pointer;
      padding: 10px 1rem 10px 1rem;

      margin: 0 15px;
      :focus {
        border: 2px solid #000;
      }
    }
    .active {
      color: #FDB00B;
      background-color: rgba(0, 0, 0, 0.144)
    }
  }
</style>
