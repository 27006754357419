<template>
  <div class="loading">
    <div class="progress-loading"></div>
    <p :class="{error: error}">{{description}}</p>
  </div>
</template>
<script>
export default {
  name: 'loading-progress',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    description () {
      if (this.title.lastIndexOf('#') !== -1) {
        return this.title.substr(0, this.title.length - 1)
      }
      return this.title
    },
    error () {
      return this.title.lastIndexOf('#') !== -1
    }
  }
}
</script>
<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.459);
  p {
    margin-top: 100px;
    text-transform: uppercase;
    color: #fff;
    &.error {
      color: #f00
    }
  }
  .progress-loading {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background-color: #000;
    animation: loading 1s linear infinite alternate-reverse 0s;
  }
}
@keyframes loading {
  0% {
    transform: translateX(-100px);
    background-color: #000;
    width: 30px;
    height: 30px;
  }
  30% {
    width: 10px;
    height: 10px;
  }
  50% {
    width: 30px;
    height: 30px;
  }
  70% {
    width: 10px;
    height: 10px;
  }
  100% {
    width: 30px;
    height: 30px;
    transform: translateX(140px);
    background-color: #26a0a5;
  }
}

@media screen and (max-width: 768px){
  @keyframes loading {
  0% {
    transform: translateX(-120px);
    background-color: #000;
    width: 30px;
    height: 30px;
  }
  30% {
    width: 10px;
    height: 10px;
  }
  50% {
    width: 30px;
    height: 30px;
  }
  70% {
    width: 10px;
    height: 10px;
  }
  100% {
    width: 30px;
    height: 30px;
    transform: translateX(120px);
    background-color: #26a0a5;
  }
}
}
</style>
